
import React, { useContext } from 'react';
import { NavLink} from 'react-router-dom';
import { Menu} from 'antd';

import { FaHotel, FaBookmark, FaUndo, FaUser, FaCalendar, FaComment, FaGift, FaTable, FaFolder, FaBoxOpen } from 'react-icons/fa';
import {
  ADMIN_PARTENAIRE_HOTEL,
  ADMIN_PARTENAIRE_CHAMBRE,
  ADMIN_PARTENAIRE_RESERVATION_LIST,
  ADMIN_RESERVATION_GLOBAL_VIEW,
  SUPER_ADMIN_LIST_HOTEL,
  ADMIN_PARTENAIRE_USER,
  ADMIN_HOTEL_PROMOTION,
  ADMIN_HOTEL_COMMENTAIRE,
  ADMIN_PARTENAIRE_MESDOCUMENTS,
  ADMIN_PARTENAIRE_RESILIER_CONTRAT,
  ADMIN_RESERVATION_DISPONIBILITE_VIEW,
  ADMIN_PARTENAIRE_EXTRA
} from 'settings/constant';
import { AuthContext } from 'context/AuthProvider';

export  function AdminPartenaireSideMenu () {
  const {user} = useContext(AuthContext);
  let menusHotel = [
    {
      label: <NavLink to={ADMIN_PARTENAIRE_HOTEL}>Editer Hôtel</NavLink>,
      key: 'hotel_edit',
      icon: <FaHotel className='Hotel' />
    },
    {
      label: <NavLink to={ADMIN_PARTENAIRE_CHAMBRE}>Chambres</NavLink>,
      key: 'chambre',
      icon:<FaHotel className='Chambre'/>
    },
    {
      label: <NavLink to={ADMIN_PARTENAIRE_EXTRA}>Extras</NavLink>,
      key: 'extras',
      icon:<FaHotel className='Extras'/>
    }
  ];


  if(user.roles.includes('ROLE_SUPER_ADMIN')
    || user.roles.includes('ROLE_ADMIN_HOTEL')) {
    menusHotel.push(
      {
        label: <NavLink title='Offres du moment' to={ADMIN_HOTEL_PROMOTION}>Offres du moment</NavLink>,
        key: 'promotion',
        icon:<FaGift className='Promotion'/>
      }
    );
  }

    menusHotel.push(
      {
        label: <NavLink title='Commentaires' to={ADMIN_HOTEL_COMMENTAIRE}>Commentaires</NavLink>,
        key: 'commentaire',
        icon:<FaComment className='Commentaire'/>
      }
    );

  
    if(user.roles.includes('ROLE_SUPER_ADMIN')
    || user.roles.includes('ROLE_ADMIN_HOTEL')) {
      menusHotel.push(
      {
        label: <NavLink title='Mes documents' to={ADMIN_PARTENAIRE_MESDOCUMENTS}>Mes documents</NavLink>,
        key: 'mesDocumens',
        icon:<FaFolder className='MesDocuments'/>
      }
    );
    menusHotel.push(
      {
        label: <NavLink title='Résilier contrat' to={ADMIN_PARTENAIRE_RESILIER_CONTRAT}>Résilier contrat</NavLink>,
        key: 'resilierContrat',
        icon:<FaFolder className='ResilierContrat'/>
      }
    );
  }


  let navigations = [
    
    {
      label: <NavLink to={ADMIN_PARTENAIRE_HOTEL}>Hôtel</NavLink>,
      key: 'hotel',
      icon: <FaHotel className='Hotel' />,
      children : menusHotel,
      group:"group"
    },
    {
      label: <NavLink to={ADMIN_PARTENAIRE_RESERVATION_LIST}>Réservations</NavLink>,
      key: 'reservation',
      icon: <FaBookmark className='Bookmark'/>,
      children : [
        {
          label: <NavLink to={ADMIN_PARTENAIRE_RESERVATION_LIST}>Liste</NavLink>,
          key: 'reservation_liste',
          icon: <FaTable className='Bookmark' />
        },
        {
          label: <NavLink title='Vue globale' to={ADMIN_RESERVATION_GLOBAL_VIEW}>Vue globale</NavLink>,
          key: 'reservationGlobalView',
          icon:<FaCalendar className='Calendar'/>
        },
        {
          label: <NavLink title='Disponibilite' to={ADMIN_RESERVATION_DISPONIBILITE_VIEW}>Disponibilité</NavLink>,
          key: 'disponibilite',
          icon:<FaBoxOpen className='BoxOpen'/>
        }
      ],
      group:"group"
    }
  ];

  if(user.roles.includes('ROLE_SUPER_ADMIN')
  || user.roles.includes('ROLE_MANAGER')
  || user.roles.includes('ROLE_AGENT_COM')
  || user.roles.includes('ROLE_ADMIN_HOTEL')) {
    navigations.push({
        label: <NavLink to={ADMIN_PARTENAIRE_USER}>Utilisateurs</NavLink>,
        key: 'user',
        icon:<FaUser className='user'/>
    })
  }

  if(user.roles.includes('ROLE_SUPER_ADMIN')
    || user.roles.includes('ROLE_MANAGER')
    || user.roles.includes('ROLE_AGENT_COM')) {
      navigations.push({
        label: <NavLink to={SUPER_ADMIN_LIST_HOTEL}>Retour</NavLink>,
        key: 'Undu',
        icon:<FaUndo className='Undu'/>
      })
  }

  return (
        <>
          <Menu
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['hotel']}
              mode="inline"
              items={navigations}
          />
        </>
    );
}