// General Page Section
export const HOME_PAGE = '/';
export const AGENTS_PAGE = '/agents';

//Obtenir app
export const OBTENIR_APPLI = "/obtenir-maresa-app";
export const REDIRECT_APPLI = '/page-obtenir-maresa-app';
// Listing Single Page Section
export const LISTING_POSTS_PAGE = '/listing';
export const SINGLE_POST_PAGE = '/hotel';
export const PAIEMENT_EFFECTUE = '/reservation/paiement-effectue';
// export const DETAILS_PAIEMENT_PAGE = "paiement";
// export const DETAILS_ANNULATION_PAGE= "annulation";
// export const DETAILS_PROPRIETE_PAGE = '/details_radisson';

export const ADD_PAGE = '/add-listing';
export const RESERVATION_PAGE = 'reservation';
export const LIST_RESERVATION="/reservation-list";
export const RESERVATION_ACCEPTED_CLIENT = "/client-accept-reservation"

export const CONTACT_PAGE = "/contact"

// Agent Profile Page Section
export const AGENT_PROFILE_PAGE = '/profile';
export const AGENT_PROFILE_FAVORITE = 'favorite-post';
export const AGENT_PROFILE_CONTACT = 'contact'; 
export const AGENT_ACCOUNT_SETTINGS_PAGE = '/account-settings';
export const AGENT_PROFILE_EDIT_PAGE = 'edit';
export const AGENT_IMAGE_EDIT_PAGE = 'change-image';
export const AGENT_PASSWORD_CHANGE_PAGE = 'change-password';
export const AGENT_PROFILE_DELETE = '/delete'; 

// Other Pages
export const PRICING_PLAN_PAGE = '/pricing-plan';
export const PRIVACY_PAGE = '/privacy';
export const ADD_HOTEL_PAGE = '/add-hotel';

// Login / Registration Page
export const LOGIN_PAGE = '/sign-in';
export const REGISTRATION_PAGE = '/sign-up';
export const CHANGE_PASSWORD_PAGE = '/change-password';
export const CHANGE_PASSWORD_CLIENT_PAGE = '/change-password-client';
export const FORGET_PASSWORD_PAGE = '/forget-password';
export const CONDITION_PAGE = '/sign-condition' ;
export const CONDITION_PAGE_PARTENAIRE = '/sign-condition-partenaire' ;

//Landing Page Admin Partenaire
export const ADMIN_PARTENAIRE_PAGE="/admin/partenaire";
export const ADMIN_PARTENAIRE_HOTEL="/admin/partenaire/hotel";
export const SIGN_PARTENAIRE_PAGE = '/sign-partenaire';
export const ADMIN_PARTENAIRE_CHAMBRE="/admin/partenaire/chambre";
export const ADMIN_PARTENAIRE_RESERVATION_LIST="/admin/partenaire/reservation";
export const ADMIN_RESERVATION_GLOBAL_VIEW="/admin/partenaire/reservation-global-view";
export const ADMIN_PARTENAIRE_CHAMBRE_EDIT="/admin/partenaire/chambre/edit";
export const ADMIN_PARTENAIRE_CHAMBRE_TYPE_HEBERGEMENT="/admin/partenaire/chambre-type-hebergement";
export const ADMIN_PARTENAIRE_CHAMBRE_TYPE_HEBERGEMENT_EDIT="/admin/partenaire/chambre-type-hebergement/edit";
export const ADMIN_PARTENAIRE_USER="/admin/partenaire/users";
export const ADMIN_HOTEL_PROMOTION="/admin/partenaire/offre-du-moment";
export const ADMIN_HOTEL_COMMENTAIRE="/admin/partenaire/commentaire";
export const ADMIN_PARTENAIRE_MESDOCUMENTS="/admin/partenaire/mes-documents";
export const ADMIN_PARTENAIRE_RESILIER_CONTRAT="/admin/partenaire/resilier-contrat";
export const ADMIN_RESERVATION_DISPONIBILITE_VIEW="/admin/partenaire/disponibilite-view";
export const ADMIN_PARTENAIRE_EXTRA="/admin/partenaire/extras";

//super admin
export const SUPER_ADMIN_INDEX="/admin";
export const SUPER_ADMIN_LIST_DOSSIER="/admin/dossiers";
export const SUPER_ADMIN_LIST_HOTEL="/admin/hotels";
export const SUPER_ADMIN_LIST_USER = '/admin/users';
export const SUPER_ADMIN_PARAMETRE="/admin/parametres";
export const SUPER_ADMIN_TRACE_UTILISATION="/admin/traces";
export const SUPER_ADMIN_VILLE="/admin/villes";
export const SUPER_ADMIN_RESERVATIONS="/admin/reservations";
export const SUPER_ADMIN_CATEGORIE_HOTEL="/admin/categorie-hotels";
export const SUPER_ADMIN_EQUIPEMENT="/admin/equipement-hebergements";
export const SUPER_ADMIN_CATEGORIE_EQUIPEMENT="/admin/categorie-equipement-hebergements";
export const SUPER_ADMIN_CHAMBRE_CATEGORIE_EQUIPEMENT="/admin/chambre/categorie-equipement-hebergements";
export const SUPER_ADMIN_CHAMBRE_EQUIPEMENT="/admin/chambre/equipements";
export const SUPER_ADMIN_SERVICE="/admin/service-hebergements";
export const SUPER_ADMIN_CATEGORIE_SERVICE="/admin/categorie-service-hebergements";
export const SUPER_ADMIN_CHAMBRE_CATEGORIE_SERVICE="/admin/chambre/categorie-services";
export const SUPER_ADMIN_CHAMBRE_SERVICE="/admin/chambre/services";
export const SUPER_ADMIN_CHAMBRE_TYPE_HEBERGEMENT="/admin/chambre/typeHebergements";
export const SUPER_ADMIN_ACCESSIBILITE="/admin/accessiblites";
export const SUPER_ADMIN_DEVISE="/admin/devises";
export const SUPER_ADMIN_HOTEL_PROMOTION='/admin/offre-du-moment';
//Articles
export const SUPER_ADMIN_ARTICLES='/admin/articles';
export const SUPER_ADMIN_CATEGORIE_ARTICLES='/admin/categorie-articles';
export const BLOG_PAGE= '/nos-blogs';
//icones
export const SUPER_ADMIN_ICONES='/admin/icones';
export const SUPER_ADMIN_MODE_PAIEMENT='/admin/mode-paiement';

export const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE??10;
export const NOM_DOMAINE = (process.env.REACT_APP_DOMAINE??'maresamada.com') +' '
    + (process.env.REACT_APP_VERSION??'v.1.0.1');
export const TAILLE_MAX_UPLOAD = process.env.REACT_APP_UPLOAD_IMAGE_SIZE_MAX?`${process.env.REACT_APP_UPLOAD_IMAGE_SIZE_MAX/1000000} Mo`:"2 Mo";
export const VERSION = process.env.REACT_APP_VERSION??"v.1.0.0";
export const ACTIVATION_COMPTE_CLIENT = "/activation-client-account";
export const SUCCES_STRIPE = "/success";
export const CANCELED_STRIPE = "/canceled";
export const PHONE_MARESA = "020 24 521 89" ;
export const ADRESSE_MARESA = "Lot III R 45 A Appartement A, Tsarafaritra Tsimbazaza";
export const EMAIL_MARESA = "commercial@maresamada.com"
export const ADRESSE_MASERA_LAT = "-18.933701446298407";
export const ADRESSE_MASERA_LONG = "47.527912856168534";
export const SLOGAN_MARESA = "Réservez l'instant, vivez l'inoubliable !";
export const NBR_TENTATIVE_PAYMENT = 5 ; 
export const TIMEOUT_TENTATIVE_PAYMENT = 5000;// en millisecond
export const UPLOAD_IMAGE_SIZE_MAX = process.env.REACT_APP_UPLOAD_IMAGE_SIZE_MAX??2000000;//10000000
export const SIEGE_ADRESSE="Villa Rasoza cn° 02 plle 14/21 Salazamay nord Toamasina 501";
export const SIEGE_TEL="+261 32 86 077 99";

export const URL_APPLE_STORE = "https://apps.apple.com/app/idxxxxx";
export const URL_GOOGLE_PLAY = "https://play.google.com/store/apps/details?id=liel.maresamada";
export const URL_SITE = "https://maresamada.com";